import React, { useState, useEffect } from "react";
import video from "../../video/video.mp4";
import mute from "../../img/icon-mute.png";
import { service } from '../../Network/service';
import WheelCarousel from "../WheelCarousel/WheelCarousel";
const Banner = () => {
  const [apiResponse, setApiResponse] = useState({ data: [] });
  const [bannerImage, setBannerImage] = useState('')

  useEffect(() => {
    service.homeBannerDetails().then(response => {
      if (response.success === true) {
        setBannerImage(response.data[0].banner3)
        while (response.data.length != 5) {
          let temObj = response.data[2];
          response.data.push(temObj);
        }
        setApiResponse(response);
      }
    });
  }, []);

  return (
    <>
      <div id="home-page" className="homePageLoad homeBannerAdj">
        <div className="all-cats">
          <section className="preview-player-wrpr">
            <div
              className="container-fluid bannerImgAdj"
              style={{
                background:
                  'url("https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/' + bannerImage + '") center center / cover no-repeat',
              }}
            ></div>
            {
              apiResponse.data.length > 0 && (
                <WheelCarousel items={apiResponse.data} />
              )
            }

          </section>
        </div>
      </div>
    </>
  );
};
export default Banner;
