import React, { useState, useEffect } from "react";
import cx from "classnames";
import SliderContext from "./context";
import ShowDetailsButton from "./ShowDetailsButton";
import Mark from "./Mark";
import "./Item.scss";


const Item = ({ movie, index }) => {
  const imageShowUrl = 'https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/';
  const imageSeriesUrl = 'https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/';
  let path = window.location.pathname;
  path = path.length == 1 ? '/home' : path;
  const [isHover, setIsHover] = useState(false);
  const handleHover = (e) => {
    setIsHover(!isHover);
  };

  return (
    <SliderContext.Consumer>
      {({ onSelectSlide, currentSlide, elementRef }) => {
        let tempActive = false;
        if (path.startsWith("/showDetails")) {
          onSelectSlide(movie);
        } else

          if (path.startsWith("/search")) {
            movie.single_video = 1;
            tempActive = currentSlide && currentSlide.video_id === movie.video_id;
          } else {

            tempActive = currentSlide && currentSlide.show_id === movie.show_id;
          }
        const isActive = tempActive;
        if (localStorage.getItem("saved" + path + index)) {
          let tempItem = JSON.parse(
            localStorage.getItem("saved" + path + index)
          );
          if (path.startsWith("/search")) {
            if (tempItem.video_id == movie.video_id) {
              onSelectSlide(movie);
              localStorage.removeItem("saved" + path + index);
            }
          } else {
            if (tempItem.show_id == movie.show_id) {
              onSelectSlide(movie);
              localStorage.removeItem("saved" + path + index);
            }
          }

        }
        return (
          <div
            ref={elementRef}
            className={cx("item", {
              "item--open": isActive,
            })}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            onClick={() => onSelectSlide(movie)}
          >
            {!path.startsWith("/showDetails") && (
              movie.single_video == 0 ? (
                <img src={imageSeriesUrl + movie.logo} alt="" />
              ) : (
                  <img src={imageShowUrl + movie.logo} alt="" />
                )
            )
            }

            {isHover && (
              <>
                <div className="thumbnail-mask"></div>
                <div className={"episodeNumber noProgress"}>
                  <span
                    className="episode-play-thumb animated fadeIn"
                    title="play"
                  ></span>
                  {
                    path.startsWith("/search") && movie.single_video == 0 ? (
                      <span aria-hidden="true" className="episode-number-hover">
                        {movie.video_title}
                      </span>
                    ) : (
                        <span aria-hidden="true" className="episode-number-hover">
                          {movie.show_name}
                        </span>
                      )
                  }

                </div>
              </>
            )}
            {/* {
              path.startsWith("/search") && ( 
                <>
                <div className="thumbnail-mask"></div>
                <span aria-hidden="true" className="episode-number-hover">
                  {movie.video_title}
                </span>
                </>
              )
            } */}

            <ShowDetailsButton onClick={() => onSelectSlide(movie)} />
            {isActive && (
              movie.single_video == 0 ? (
                <Mark image={imageSeriesUrl + movie.logo} />
              ) : (
                  <Mark image={imageShowUrl + movie.logo} />
                ))}


          </div>
        );
      }}
    </SliderContext.Consumer>
  );
};

export default Item;
