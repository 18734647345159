import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { service } from '../../Network/service';
import SliderContext from "./context";
import "./Item.scss";

const Item = ({ movie, index, showId, type }) => {
  const [payPerView, setPayperView] = useState();
  const [rental, setRental] = useState();
  const [monthly, setMonthly] = useState();
  const [yearly, setYearly] = useState();
  useEffect(() => {
    if (type == 'episode')
      movie.subscriptions.map((item) => {
        if (item.subscription_type == 1) {
          item.subscription_type_name = "Pay Per View"
          setPayperView(item);
        } else if (item.subscription_type == 6) {
          item.subscription_type_name = "Rental"
          setRental(item);
        } else if (item.subscription_type == 4) {
          item.subscription_type_name = "Yearly"
          setYearly(item);
        } else if (item.subscription_type == 3) {
          item.subscription_type_name = "Monthly"
          setMonthly(item);
        }
      })
  }, []);
  let history = useHistory();
  const imageURLPrefix =
    "https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/";
  let path = window.location.pathname;
  path = path.length == 1 ? '/home' : path;

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("force_cold_reload");
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    setTimeout(() => {
      window.location.href = "/signIn";
    }, 1000);
  };

  const onEpisodePlay = (e) => {
    e.preventDefault();
    localStorage.setItem(
      "selectEpisode" + path + index, true
    );

    localStorage.setItem(
      "episodeFlag" + showId, true
    );

    localStorage.setItem(
      "episodeFlag", true
    );
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
    var x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
    var y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
    const scrollPosition = [x, y];
    localStorage.setItem('scrollPosition' + path, JSON.stringify(scrollPosition));
    movie.type = type;
    movie.show_id = showId;
    localStorage.setItem(
      "saved" + path + index,
      JSON.stringify(movie)
    );
    localStorage.setItem('videoPath', path);
    service.videoSubscription(movie.video_id).then(response => {

      let videoDetails = response.data;
      let subFlag = true;
      let uId = 280;
      let user_id = service.getCookie('userId');
      if (user_id) {
        uId = user_id;
      }
      if (videoDetails.length > 0) {
        service.userSubscription(uId).then(useResponse => {
         

          if (useResponse.forcibleLogout === true) {
            alert(
              "Sorry, You’ve reached the maximum Device limit. Please log in again!"
            );
            service.logoutAll(uId).then((res) => {
              setTimeout(() => {
                redirectToLogin();
              }, 1000);
            });
          } else {
            var userData = useResponse.data;
          videoDetails.map(function (subscription, index) {

            // if (useResponse.forcibleLogout === false) {
            if (useResponse.data.length == 0 && subFlag) {
              subFlag = false;
              service.setCookie("showId", movie.show_id, 10);
              service.setCookie("videoId", movie.video_id, 10);
              history.push({
                pathname: '/subscription',
                state: {
                  videoData: movie.video_id
                }
              })
            } else {
              let subscribedVideo = userData.filter(e => e.sub_id == subscription.publisher_subscription_id);
              console.log('subscribedVideo', subscribedVideo); 
              if (subscribedVideo.length == 0 && (index + 1) < videoDetails.length) {
                return
              }
              if (subscribedVideo.length == 0 && subFlag && (index + 1) == videoDetails.length) {
                subFlag = false;
                service.setCookie("showId", movie.show_id, 10);
                service.setCookie("videoId", movie.video_id, 10);
                history.push({
                  pathname: '/subscription',
                  state: {
                    videoData: movie.video_id
                  }
                })
              } else if (subFlag) {
                subFlag = false;
                history.push({
                  pathname: "/videoDetails",
                  state: { movie: movie },
                });
              }
            }

          });
          }




          
        });
      } else {
        history.push({
          pathname: "/videoDetails",
          state: { movie: movie },
        });
      }
    });
  };
  const [isHover, setIsHover] = useState(false);
  const handleHover = (e) => {
    setIsHover(!isHover);
  };
  return (
    <SliderContext.Consumer>
      {({ onPlayepisode, currentSlide, elementRef }) => {
        // const isActive =
        //   currentSlide && currentSlide.video_id === movie.video_id;
        return (
          <div
            ref={elementRef}
            className="item"
          /*className={cx("item", {
            "item--open": isActive,
          })} */
          >
            <div
              className="thumbnail-episode"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              <img src={imageURLPrefix + movie.thumbnail} alt="" />
              <div className="thumbnail-mask"></div>

              <div className="episodeNumber noProgress">
                {isHover && (
                  <>
                    <span
                      className="episode-play-thumb animated fadeIn"
                      title="play"
                      onClick={onEpisodePlay}
                    ></span>
                  </>
                )}
                <span
                  aria-hidden="true"
                  className={
                    isHover ? "episode-number-hover" : "episode-number"
                  }
                >
                  {movie.video_title}
                </span>
              </div>

            </div>
            <div className="row ml-1 ">
              {
                payPerView != undefined ? <span ><button className="item__blink_badge" onClick={onEpisodePlay} data-toggle="tooltip" title={`Buy :  ${payPerView.price}`} >Buy</button></span> : null
              }
              {
                rental != undefined ? <span ><button className="item__blink_badge1" onClick={onEpisodePlay} data-toggle="tooltip" title={`Rent :  ${rental.price}`} >Rent</button></span> : null
              }
              {
                (yearly != undefined && monthly == undefined) ? <span ><button className="item__blink_badge2" onClick={onEpisodePlay} data-toggle="tooltip" title={`Yearly :  ${yearly.price}`} >Subscribe</button></span> : null
              }
              {
                (monthly != undefined && yearly == undefined) ? <span ><button className="item__blink_badge2" onClick={onEpisodePlay} data-toggle="tooltip" title={`Monthly :  ${monthly.price}`} >Subscribe</button></span> : null
              }
              {
                (yearly != undefined && monthly != undefined) ? <span ><button className="item__blink_badge2" onClick={onEpisodePlay} data-toggle="tooltip" title={`Yearly :  ${yearly.price}  Monthly :  ${monthly.price}`} >Subscribe</button></span> : null
              }

            </div>
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
};

export default Item;
