import React, { useState, useEffect } from "react";
import { service } from "../../Network/service";
import ReactHlsPlayer from "react-hls-player";
import "react-multi-carousel/lib/styles.css";
import {
  convertTimeToLocal,
  deviceDetect,
  playerController,
  ssaiAdParam
} from "../../Utils/utils";
var liveThumbnailUrl = "https://gizmeon.mdc.akamaized.net/thumbnails/images/";
var pause = false;

const handleScroll = () => {
  let playerId = "singleVideo";
  if (deviceDetect() === true) {
    playerController(600, playerId);
  } else {
    playerController(150, playerId);
  }
};

const LiveContainer = () => {
  const [channels, setChannels] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [videoPlayer, setVideoPlayer] = useState();
  const [isVideoPause, setIsVideoPause] = useState(true);
  useEffect(() => {
    service.getLiveChannels().then((response) => {
      setLogo(response.data[0].logo);
      setChannels(response.data[0]);

      if (response.data[0].live_link) {
        "start_time" in response.data[0].now_playing
          ? setStartTime(response.data[0].now_playing.start_time)
          : setStartTime("");
        "end_time" in response.data[0].now_playing
          ? setEndTime(response.data[0].now_playing.end_time)
          : setEndTime("");
        "video_title" in response.data[0].now_playing
          ? setVideoTitle(response.data[0].now_playing.video_title)
          : setVideoTitle("");
        setVideoPlayer(
          // <ReactHlsPlayer
          //   id="singleVideo"
          //   url={response.data[0].live_link}
          //   autoplay={true}
          //   controls={true}
          //   width={"100%"}
          //   height={"100%"}
          // />
          ssaiAdParam(response.data[0]).then(params => {
            window.playLivePlayer('singleVideo', response.data[0], params);
        })
        );
      }
    });
    // window.addEventListener("scroll", handleScroll);
  }, []);
  window.onLiveVideoPlay = (vd) => {
    let event = 'POP03';
    service.onVideoPlayFunction(vd, event).then(response => {
    })
}
window.onLiveVideoPause = (vd) => {
    let event = 'POP04';
    setIsVideoPause(false);
    pause = true
    service.onVideoPlayFunction(vd, event).then(response => {
    })
}
window.onLiveVideoEnd = (vd) => {
    let event = 'POP05';
    service.onVideoPlayFunction(vd, event).then(response => {
    })
}
  return (
    <div className="entireBanner">
      <div className="hpLiveBanner">
        <div className="liveVideoWrapper">
          {videoPlayer && (
             <video id="singleVideo" className="video-js vjs-default-skin mainPlayer"
             controls preload="auto"
             autoPlay >
         </video>
          )}
          <div className="hpWrapperVideo">
            <section className="movieTextWrapper vpRelatedMargin">
              <div className="vpRelatedImage">
                <img
                  alt={videoTitle}
                  src={liveThumbnailUrl + logo}
                  width="100%"
                />
                <div className="liveTvBlackOverlay"></div>
                <div className="liveTvPlay"></div>
              </div>
              <div className="movieTextFlex">
                <div className="movieCatYear">
                  <div>
                    <div className="movieCategory mcMargin">
                      <div>
                        {startTime &&
                          convertTimeToLocal(startTime)}
                        {startTime && "-"}{" "}
                        {endTime &&
                          convertTimeToLocal(endTime)}
                      </div>
                    </div>
                  </div>
                </div>
                <h3>
                  <div className="linkButton movieTextHeading">
                    {videoTitle}
                  </div>
                </h3>
              </div>
            </section>
          </div>
        </div>
        <div className="overlayTiles"></div>
      </div>
    </div>
  );
};
export default LiveContainer;
